/**
 * Flexible Zig-Zag Content
 */

@import "assets/scss/utilities/mixins";
@import "assets/scss/utilities/variables";
@import "../../../node_modules/foundation-sites/scss/util/_unit.scss";
@import "../../../node_modules/foundation-sites/scss/util/_breakpoint.scss";

$breakpoints: (
    small: 0,
    medium: 640px,
    large: 1024px,
    xlarge: 1200px,
    xxlarge: 1440px,
);

.zigzag {
    position: relative;
    z-index: 1;

    .section-statement {
        font-family: $Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: rem-calc(18px);
        line-height: rem-calc(28px);
        text-align: center;
        color: #6C6E6C;
        margin-bottom: 1rem;
    }
}

.zigzag-row {
    position: relative;
    z-index: 1;
    padding-bottom: 3rem;

    @include breakpoint(xlarge) {
        padding-bottom: 4.5rem;
    }

    &:last-child {
        padding-bottom: 0.5rem;
    }

    @include breakpoint( medium down ) {
        margin-top: 0rem;
        margin-bottom: 2.5rem;   

        // override
        align-items: flex-start !important;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .zigzag-row__copy {
        @include breakpoint( small only ) {
            order: 1;      
        }
    }

    .zigzag-row__header,
    h2,
    h3 {
        color: black;
        margin-bottom: 1.3rem;

        @include breakpoint( small only ) {
            margin-bottom: 0.75rem;   
        }
    }

    .zigzag-row__header {
        @include breakpoint( small only ) {
            font-size: rem-calc(22px);   
        }
    }

    .zigzag-row__text {
        font-family: $Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: rem-calc(16px);
        line-height: (28/16);
        color: #6C6E6C;

        @include breakpoint( small only ) {
            color: #3a3a3a;
            font-size: rem-calc(16);   
        }

        p {
            all: inherit;
            margin-bottom: 1rem;

            a:not(.button) {
                color: var(--law-darkgray);
                text-decoration: underline;
            }

            &:last-child {
                @include breakpoint(xlarge) {
                    margin-bottom: 0;    
                }
            }

            @include increased-contrast {
                color: #000;
                font-size: rem-calc(18px);
            }
        }

        p > a.button.small {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: rem-calc(19px);
            line-height: rem-calc(36px);
            text-align: center;
            color: #FFFFFF;
            padding: rem-calc(5px 55px 6px);
            margin-bottom: 0;
        }
    }

    .zigzag-row__image {
        text-align: right;

        &.medium-custom-width {
            flex: 0 0 rem-calc(480px);

            @include breakpoint(medium down) {
                flex: 0 1 rem-calc(380px);    
            }
        }

        @include breakpoint( small only ) {
            order: 0;
            margin-bottom: 2rem;
        }

        img {
            box-shadow: var(--law-dropshadow);
            border-radius: var(--law-radius);
            width: rem-calc(480px);
            height: rem-calc(340px);
            object-fit: cover;

            filter: contrast(1.05);
            image-rendering: pixelated;

            @include breakpoint(medium down) {
                width: 100%;
                height: auto;  
                max-width: 100%;  
            }
        }
    }

    &.reverse-content-order {

        .zigzag-row__copy {
            order: 1;

            @include breakpoint(large) {
                padding-left: rem-calc(60px);
            }
        }

        .zigzag-row__image {
            order: 0;
            text-align: left;
        }

    }
    
    &:not(.reverse-content-order) {
        .zigzag-row__copy {
           
        }
    }
}

.zigzag-row:not(.reverse-content-order) .zigzag-row__copy {
    @include breakpoint(xlarge) {
        padding-right: 4rem;
    }
}

.zigzag-cta-wrapper {

    .cell {
        @include breakpoint( small only ) {
            padding-inline: 0;   
        }
    }

}

.zigzag-cta {
    background: #FFFFFF;
    box-shadow: rem-calc(6px 4px 28px) rgba(0, 0, 0, 0.13);
    border-radius: rem-calc(40px);
    padding: rem-calc(42px 62px 32px 62px);
    width: 100%;
    position: relative;
    z-index: 1;
    margin-top: 2rem;
    @include breakpoint(xlarge) {
        margin-top: 3rem;
        transform: translate(0, 24px)        
    }

    @include breakpoint( small only ) {
        padding: 2.5rem 2rem;
    }

    &__container {
        max-width: rem-calc(944px);
        margin-left: auto;
        margin-right: auto;

        @include breakpoint( small only ) {
            max-width: unset;  
            width: 100%; 
        }
    }

    &__text {
        font-family: $Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: rem-calc(16px);
        line-height: rem-calc(33px);
        text-align: center;
        color: #505050;

        p {
            all: inherit;
        }

        @include increased-contrast {
            p {
                color: #000;
            }
        }

    }
}


/**
 * Curves
 */


.single.single-service .zigzag-curve {
    display: none;
}

.single.single-service:not(.postid-602) .content-area .zigzag[style]:first-child .zigzag-curve {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    display: block;

    // transform: translate(0, 145px);

    @include breakpoint(1900 down) {
        transform: translate(0, 0);
    }

    img {
        width: 100%;
        max-width: unset;
    }
}


// About Us

.page.page-id-586 {

  .zigzag-curve.zigzag-curve--about-us {    
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 0;

    @include breakpoint(xlarge) {
        transform: translateY(40px);
    }

    img {
        width: 100%;
    }
  }  

  .zigzag:not([style]) .zigzag-curve.zigzag-curve--about-us {
    display: none;
  }


  
} 



















